@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel * {
    line-height: normal;
}

.carousel .slide .legend:not(.bottom-element) {
    bottom: inherit!important;
    top: 12px!important;
}

.carousel .slide .legend {
    background: transparent!important;
    color: white!important;
    opacity: 1!important;
    font-size: 18px!important;
    font-weight: bold;
}

ul.slider > li:nth-child(4) {
    right: 0;
}

.qheading {
    font-size: 18px;
    line-height: 1.2;
    font-style: italic;
}

@media (min-width: 1200px) {
    .qheading {
        font-size: 24px;
    }
}
